<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >

      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pl-2 my-2" style="width: 100%">

          <!-- Left-aligned content -->
          <div class="d-inline-flex align-center">
            <v-text-field
              v-model="name"
              class="ml-3 custom-search-perm"
              outlined
              clearable
              hide-details
              dense
              :label="$lang.labels.instanceId"
              data-cy="search-by-instanceId"
              style="min-width: 400px"
            ></v-text-field>
            <v-autocomplete
              v-model="type"
              hide-selected
              outlined
              dense
              clearable
              :items="formattedTypes"
              hide-no-data
              class="ml-2 custom-search-perm"
              hide-details
              :label="$lang.labels.type"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-format-list-bulleted"
              data-cy="search-by-instanceType"
            ></v-autocomplete>
          </div>

          <!-- Right-aligned refresh button -->
          <div class="ml-auto">
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
          </div>

        </div>
      </template>

      <template v-slot:item.instanceId="{ item }">
        <a @click="name = item.instanceId"># {{ item.instanceId }}</a>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.started="{ item }">
        <div>{{ item.started | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.finished="{ item }">
        <div>{{ item.finished | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.heartbeat="{ item }">
        <div>{{ item.heartbeat | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { definitions } from '@/utils/definitions'
import { debounce } from 'lodash'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      options: {},
      totalItems: 0,
      headers: [
        { text: this.$lang.labels.instanceId, value: 'instanceId', sortable: true },
        {
          text: this.$lang.labels.type,
          align: 'start',
          sortable: true,
          value: 'type'
        },
        { text: this.$lang.labels.started, value: 'started', sortable: true },
        { text: this.$lang.labels.finished, value: 'finished', sortable: true },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.heartbeat, value: 'heartbeat', sortable: true }
      ],
      name: '',
      type: '',
      types: definitions.InternalJob?.properties?.type?.enum || [],
      lock: true
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings']),
    formattedTypes () {
      return this.types.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  watch: {
    name: {
      handler: debounce( function (val) {
        if (val && val.length > 1) {
          this.sendQuery()
        } else if (!val || val.length < 1) {
          this.sendQuery()
        }
      }, 500)
    },
    type: {
      handler(val) {
        if (val && val.length > 1) {
          this.sendQuery()
        } else if (!val || val.length < 1) {
          this.sendQuery()
        }
      }
    },
    options: {
      handler () {
        this.sendQuery(false)
        this.savePreFill()
      },
      deep: true
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    if (this.$route && this.$route.query && (this.$route.query.name || this.$route.query.type || this.$route.query.instanceId)) {
      this.lock = true
      if (this.$route.query.type) {
        this.type = this.$route.query.type
      }

      if (this.$route.query.instanceId) {
        this.name = this.$route.query.instanceId
      }

      if (this.$route.query.name) {
        this.name = this.$route.query.name
      }

      setTimeout(() => this.loadPreFill(), 200)
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.logInternalJobsList) {
          this.lock = true
          this.name = preFill.logInternalJobsList.name
          this.type = preFill.logInternalJobsList.type
          this.options = preFill.logInternalJobsList.options

          setTimeout(() => this.loadPreFill(), 200)
        }
      } else {
        this.lock = false
        this.sendQuery()
      }
    }
  },
  methods: {
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.logInternalJobsList) preFill.logInternalJobsList = {
          name: '',
          type: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.logInternalJobsList.name = this.name
        preFill.logInternalJobsList.type = this.type
        preFill.logInternalJobsList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.lock = false
      this.savePreFill()
      this.sendQuery()
    },
    async sendQuery(resetPage = true) {
      if (this.lock) return
      const obj = { options: this.options, instanceId: this.name  }

      if (this.type) {
        obj.type = this.type
      }

      obj.resetPage = resetPage

      this.$emit('fetchInternalJobs', obj)
      this.savePreFill()
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
